import {
	ChangeDetectorRef,
	Component,
	Inject,
	Input,
	OnInit,
	SimpleChanges
} from '@angular/core';
import { UserAvatarDisplayType } from '../../../commons/components/user-avatar/user-avatar.component';
import { preventInitialChildAnimations } from '../../../commons/animations/preventInitialChildAnimations';
import { commentEntryAnimation } from '../../animations/commentEntryAnimation';
import { Content } from '../../../shared/model/content/Content';
import { Product } from '../../../shared/model/data/shop/product/Product';
import { UserSessionService } from '../../../auth/session/services/user-session/user-session.service';
import { ApiCommunicationService } from '../../../shared/model/services/api-communication/api-communication.service';
import { Comment } from '../../../shared/model/data/social/Comment';
import { CommentType } from '../../../shared/model/data/enums/CommentType';

@Component({
	selector: 'app-comments-display',
	templateUrl: './comments-display.component.html',
	styleUrls: ['./comments-display.component.scss'],
	animations: [preventInitialChildAnimations, commentEntryAnimation]
})
export class CommentsDisplayComponent implements OnInit {
	@Input()
	public item: Content | Product;

	// display options for components
	public _userAvatarDisplayType = UserAvatarDisplayType.NAME_NEXT_TO_IMAGE;

	public replyingToComment: Partial<Comment>;

	constructor(
		public userSessionService: UserSessionService,
		private api: ApiCommunicationService,
		private cd: ChangeDetectorRef,
		@Inject(UserSessionService) public sessionService: UserSessionService
	) {}

	ngOnInit() {
	}

	public deleteComment(id: string) {
		if (!id) {
			console.error("Can't delete comment, no ID");
			return;
		}

		this.api
			.comment()
			.deleteComment(id)
			.subscribe(() => {
				const idx = this.item.comments.findIndex((c) => c._id === id);
				if (idx > -1) {
					this.item.comments.splice(idx, 1);
					this.cd.detectChanges();
				}
			});
	}

	public deleteReply(comment: Partial<Comment>, id: string) {
		this.api
			.comment()
			.deleteComment(id)
			.subscribe(() => {
				const idx = comment.comments.findIndex((c) => c._id === id);
				if (idx > -1) {
					comment.comments.splice(idx, 1);
					this.cd.detectChanges();
				}
			});
	}

	showReplyInput(comment: Partial<Comment>) {
		this.replyingToComment = comment;
		setTimeout(() => {
			document.getElementById('reply-comment-input').focus();
		}, 250);
	}

	sendReply(comment: Partial<Comment>, event: KeyboardEvent) {
		if (event.key === 'Enter') {
			// the source element of the event
			const inputElement: HTMLInputElement = <HTMLInputElement>(
				event.srcElement
			);

			// request to send
			const requestData = new Comment();
			requestData.message = inputElement.value;
			requestData.content = comment._id;
			requestData.type = CommentType.COMMENT;

			this.api
				.comment()
				.comment(requestData)
				.subscribe((res) => {
					// add comment from server to the comments
					// this.post.numberOfComments++;
					requestData.user = this.sessionService.userDataValue;
					requestData._id = res._id;
					comment.comments.push(requestData);

					// reset field
					(<HTMLInputElement>event.srcElement).value = '';

					// let the change detector know that changes happened
					this.replyingToComment = null;
					this.cd.markForCheck();
				});
		}
	}
}
